// html {
//     box-sizing: border-box;
//   }

//   *, *:before, *:after {
//     box-sizing: inherit;
//   }

//   body {
//     display: flex;
//     flex-direction: column;
//     height: 100vh;
//     width: 100vw;
//     margin: 0;
//     font-family: proxima-nova !important;
//     color: #1F2430;
//     background-color: #ffffff;
//   }

.background-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  flex-grow: 1;
  padding: 24px;
}
@media (min-width: 768px) {
  .background-container {
    //   background-image: url("/static/register/img/login-bg-left.svg"), url("/static/register/img/login-bg-right.svg");
    background-position: top left, 75% 0%;
    background-repeat: no-repeat;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  max-width: 385px;
}

.logo {
  margin-bottom: 16px;
}

.heading {
  color: #1f2430;
  font-size: 24px;
  font-weight: normal;
  line-height: 29px;
  margin-top: 0;
  margin-bottom: 32px;
  text-align: center;
}
@media (min-width: 768px) {
  .heading {
    margin-left: calc((-100vw / 2 + 385px / 2) + 24px);
    margin-right: calc((-100vw / 2 + 385px / 2) + 24px);
  }
}

.large-button,
.submit-btn,
.facebook-login-button {
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;
}

.facebook-login-button {
  background: #1a77f2;
  margin-bottom: 16px;
}
.facebook-login-button:hover {
  background: #0661da;
}
@media (min-width: 768px) {
  .facebook-login-button {
    margin-bottom: 24px;
  }
}

.social-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.social-login-button {
  height: 40px;
  border-radius: 4px;
  padding: 8px;
  cursor: pointer;
}
.social-login-button.google {
  background: #ffffff;
  border: 1px solid #d1d1d1;
}
.social-login-button.google:hover {
  background: #ededed;
}
.social-login-button.google .social-link {
  color: #1f2430;
}
.social-login-button.apple {
  background: #050708;
}
.social-login-button.apple:hover {
  background: #3c3c3c;
}
.social-login-button.linkedin {
  background: #007ab6;
}
.social-login-button.linkedin:hover {
  background: #00608f;
}
.social-login-button.twitter {
  background: #5aaaf4;
}
.social-login-button.twitter:hover {
  background: #3e8dd6;
}
.social-login-button .social-link {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-decoration: none;
}
@media (min-width: 768px) {
  .social-login-button .social-link {
    font-size: 14px;
    line-height: 14px;
  }
}

.social-icon {
  margin-right: 12px;
}

.separator {
  color: #000000;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  position: relative;
  user-select: none;
  text-align: center;
  padding: 16px 0;
}
.separator:before,
.separator:after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  width: calc(50% - 24px);
  height: 1px;
  background-color: #d1d1d1;
}
.separator:before {
  left: 0;
}
.separator:after {
  right: 0;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 12px 24px;
}
.input-container.error {
  border: 1px solid #ef5228;
}
.input-container + .input-container {
  margin-top: 16px;
}
.input-container input {
  color: #000000;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  border: none;
  flex-grow: 1;
}
.input-container input:focus {
  outline: none;
}

.show-password {
  cursor: pointer;
}
.show-password.active path:first-child {
  stroke: #515fbc;
}
.show-password.active path:last-child {
  fill: #515fbc;
}
.show-password:not(.active):hover path:first-child {
  stroke: #858585;
}
.show-password:not(.active):hover path:last-child {
  fill: #858585;
}

.input-group {
  position: relative;
}

.hidden {
  display: none;
}

.error-message {
  color: #ef5228;
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .error-message {
    position: absolute;
    left: calc(100% + 32px);
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    max-width: 128px;
  }
}

.submit-btn {
  margin-top: 24px;
  margin-bottom: 32px;
  background: #515fbc;
  border: none;
}
.submit-btn:hover {
  background: #4551a0;
}

.forgot-password {
  color: #515fbc;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
  display: block;
  text-align: center;
  margin-bottom: 24px;
}
.forgot-password:hover {
  color: #4551a0;
}

.register {
  display: flex;
  justify-content: center;
  align-items: center;
}
.register a {
  color: #515fbc;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  text-decoration: none;
}
.register a:hover {
  color: #4551a0;
}
.register > * + * {
  margin-left: 16px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 32px;
}
@media (min-width: 768px) {
  .footer {
    justify-content: flex-end;
    text-align: right;
    margin-top: 16px;
  }
}
.footer .copyright {
  font-size: 14px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .footer .copyright {
    font-size: 16px;
  }
}
.footer__links a {
  color: #515fbc;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-decoration: none;
}
.footer__links a:hover {
  color: #4551a0;
}
@media (min-width: 768px) {
  .footer__links a {
    color: #515fbc;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-decoration: none;
  }
  .footer__links a:hover {
    color: #4551a0;
  }
}
.footer__links > * + * {
  margin-left: 16px;
}

.companies {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f9f9f9;
  height: 120px;
  margin-top: 32px;
  margin-left: -24px;
  margin-right: -24px;
}
.companies .company-list {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 16px;
}
@media (min-width: 768px) {
  .companies {
    height: 93px;
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .companies .company-list {
    width: auto;
    margin-top: 0;
  }
  .companies * + * {
    margin-left: 32px;
  }
}

.back-to-button {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background: #515fbc;
}
.back-to-button:hover {
  background: #4551a0;
}

.description {
  color: #1f2430;
  font-size: 14px;
  font-weight: normal;
  line-height: 135%;
  text-align: center;
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .description {
    margin-left: calc((-100vw / 2 + 385px / 2) + 24px);
    margin-right: calc((-100vw / 2 + 385px / 2) + 24px);
    font-size: 20px;
  }
}

.forgot-password-page .heading {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .forgot-password-page .heading {
    font-size: 24px;
    line-height: 29px;
  }
}

@media (min-width: 768px) {
  .sign-up-page .background-container {
    align-items: center;
    //   background-image: url("/static/register/img/sign-up-bg-2.svg");
    background-position: center;
    background-size: cover;
  }
}
.sign-up-page .content-wrapper {
  margin-bottom: -24px;
}
@media (min-width: 768px) {
  .sign-up-page .content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    max-width: 810px;
    max-height: 850px;
    margin-bottom: 0;
    padding-top: 32px;
    background: #ffffff;
    border-radius: 4px;
  }
}
.sign-up-page .heading {
  margin-bottom: 8px;
}
.sign-up-page .description {
  font-size: 20px;
}

.login {
  border: 1px solid #d3d3d3;
  border-radius: 0.5em;
  margin-bottom: 1em;
  margin: auto;
  text-align: left;
  width: 400px;
  height: 400px;
  padding: 50px;
  background: #f6f6f8;
  display: grid;
  align-content: center;
  &.form {
    text-align: left;
  }

  &.form-control {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: left;

    color: #b5b5b5;
    width: 300px;
  }
  &.form-label {
    width: 300px;
    height: 18px;

    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    color: #ffffff;
    padding-right: 10px;
    margin-right: 10px;
  }
  &.button {
    width: 258px;
    height: 18px;

    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    // line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
  .form-control:not(textarea) {
    height: 45px !important;
  }
}

.login-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #0d182f;
}

.login-textarea {
  width: 300px;
  height: 34px;

  background: #ffffff;
  border: 1px solid #e7e8ec;
  box-sizing: border-box;
}

.login-btn-text {
  width: 300px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.login-btn {
  width: 100%;

  background: #7163ff;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  /* margin: 10px; */
  border: 1px solid #e7e8ec;
}

/*# sourceMappingURL=common.css.map */
