/**************** variables ********************/
$borderValue: 1px solid #e9e9e9;
$side-panel-list-hover-bg: #f1f5fb;

/***********side-cont-wrapper***** font face ********************/

@font-face {
  font-family: "ProximaNova";
  src: local("ProximaNova"), url("./fonts/1.woff") format("woff"), url("./fonts/2.woff") format("woff"),
    url("./fonts/3.woff") format("woff"), url("./fonts/4.woff") format("woff"), url("./fonts/5.woff") format("woff");
}

/**************** mixins ********************/

@mixin title-mixin {
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
}

@mixin folder-icons {
  display: flex;
  align-items: center;
  line-height: 12px;
  margin-right: 5px;
}

@mixin search-input {
  .form-group.search-input {
    margin-bottom: 0 !important;
    position: relative;

    img {
      position: absolute;
      top: 7px;
      left: 7px;
    }

    .form-control {
      padding-left: 28px;
      height: 30px;
      // border: 1px thin #fff;
    }
  }
}

/**************** end of mixins ********************/

/**************** element overrides ********************/
* {
  font-family: ProximaNova !important;
}

button,
a {
  display: inline-flex;
  align-items: center;
  align-content: center;
  z-index: 1;
  .default {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  }
  .btn-link {
    color: #5586ef !important;
  }
  &.box-shadow {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  }
}

.form-group {
  position: relative;
}

.model-open .modal {
  overflow: hidden;
  overflow-y: auto !important;
}

/* TO OVERRIDE MOZILLA CHECKBOX LAYOUT */
input[type="checkbox"] {
  -moz-appearance: none;
  height: 1em;
  width: 1em;
  border: 1px solid gray;
  border-radius: 3px;

  // &:checked::before {
  //   background-color: #3f65b7;
  // }

  // &:checked {
  //   background-color: #3f65b7;
  // }

  // &:checked::after {
  //   background-color: #3f65b7;
  // }

  // .checkbox:checked {
  //   background-color: #3f65b7;
  // }
}

textarea {
  resize: none;
  font-style: normal;
  font-weight: normal;
  border: 1px solid rgb(227, 229, 233);

  &:focus {
    border: 1px solid #5586ef;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    color: #0d182f;
    caret-color: black;
  }
}

// input[type="text"] {
//   border: 1px solid rgb(227, 229, 233);
//   border-radius: 4px;

//   &:focus {
//     border: 1px solid #5586ef;
//     box-sizing: border-box;
//     border-radius: 4px;
//     outline: 0;
//     box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
//     color: #0d182f;
//     caret-color: black;
//   }
// }

input[type="password"] {
  border-radius: 4px;

  &:focus {
    border: 1px solid #5586ef;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    color: #0d182f;
    caret-color: black;
  }
}

input[type="email"] {
  border-radius: 4px;

  &:focus {
    border: 1px solid #5586ef;
    box-sizing: border-box;
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
    color: #0d182f;
    caret-color: black;
  }
}

/**************** end of element overrides ********************/

.icon {
  display: inline-flex;
  height: 24px;
  width: 24px;
  &.prev {
    &-black {
      background-image: url("./icons/prev-black.svg");
    }
  }
  &.next {
    &-white {
      background-image: url("./icons/next-white.svg");
    }
  }
}

.navbar {
  border-bottom: $borderValue;
  background: #ffffff;
}

.side-panel {
  display: flex;
  border-right: $borderValue;
  height: 100%;
  width: 310px;
  z-index: 1;
  background: #fff;
  position: relative;
  margin-left: 310px;
  float: left;

  .search-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      border-bottom: $borderValue;
    }

    @include search-input;
  }
}

.side-panel-container {
  display: flex;
  border-right: 1px solid #e9e9e9;
  height: 100%;
  width: 20%;
  z-index: 1;
  background: #fff;
  position: relative;
  float: left;
  flex-direction: column;
  max-width: 25%;
  min-width: 20%;

  .search-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      border-bottom: $borderValue;
    }

    @include search-input;
  }
}

/* width */
::-webkit-scrollbar {
  position: static;
  width: 4px;
  left: 0px;
  top: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b8bdc7;
  border-radius: 4px;
  height: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

// @media only screen and (max-width: 1200px) {
//   .side-panel-container { width: 25%; }
// }

.preset-sidepanel-container {
  display: flex;
  border-right: 1px thin #e9e9e9;
  height: 100%;
  width: 20%;
  z-index: 1;
  background: #fff;
  position: relative;
  float: left;
  flex-direction: column;
  max-width: 25%;
  min-width: 20%;

  .search-container {
    display: flex;
    flex-direction: column;
    flex: 1;

    & > div {
      border-bottom: $borderValue;
    }

    @include search-input;
  }

  .preset-footer {
    display: flex;
    justify-content: center;
    padding: 12px 16px;
    border-top: 1px solid #e9e9e9;
    text-align: center;
    border: 1px solid #e9e9e9;
    padding: 10px;
    height: 56px;
    // position: sticky;
    // bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
  }
}

#selectAll {
  background-color: #0d182f;
}

.content-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-left: 20%;
  position: absolute;
  width: calc(100% - 20%);
  height: calc(100% - 51px);
  background: #f6f6f8;
  min-width: calc(100% - 20%);
  max-width: calc(100% - 25%);
  overflow-y: scroll;
  cursor: auto;
  // caret-color: transparent;
}

.content-container-preset {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-left: 315px;
  position: absolute;
  width: calc(100% - 264px);
  height: calc(100% - 51px);
  background: #f6f6f8;
}

// .form-control:not(textarea) {
//   height: 30px !important;
// }

.folder {
  &-title {
    @include title-mixin;
  }
  &-container {
    height: 45vh;
  }
  &-name {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  &-content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 37vh;

    ul {
      margin-left: 5px;
      padding: 0;
      line-height: 24px;

      li {
        font-size: 12px;
        line-height: 16px;
        font-style: normal;
        font-weight: normal;
        padding: 5px;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        margin: 5px 0;
        align-items: center;
        cursor: pointer;
        caret-color: transparent;

        .count {
          margin-left: 10px;
          display: flex;
          align-items: center;
        }

        // &:first-child {
        //   &::before {
        //     content: url("./icons/favourites.svg");
        //     @include folder-icons;
        //   }
        // }

        // &:not(:first-child) {
        //   &:not(.selected)::before {
        //     content: url("./icons/folder-close.svg");
        //     @include folder-icons;
        //   }
        //   &.selected::before {
        //     content: url("./icons/folder-open.svg");
        //     @include folder-icons;
        //   }
        // }

        &.selected {
          background-color: $side-panel-list-hover-bg;
          border-radius: 2px;
        }

        &:hover {
          background-color: $side-panel-list-hover-bg;
          border-radius: 2px;

          .button-icon {
            display: initial;
          }
        }
      }
    }
    .folder-action {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-left: auto;

      .button-icon {
        display: none;
      }
    }
  }
}

.recent-projects {
  border-bottom: none !important;
  cursor: auto;

  .title {
    @include title-mixin;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      // list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 20px;
      align-items: center;
      font-size: 14px;
      line-height: 16px;

      &:hover {
        background-color: $side-panel-list-hover-bg;

        .action-button {
          display: initial;
        }
      }

      .head {
        & > span {
          font-weight: 600;
        }
      }

      .middle,
      .footer {
        font-size: 12px;
      }
    }
  }

  .action-button {
    display: none;
  }
}

#toolbar {
  padding: 10px 20px;
  height: 51px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  background: #fcfcfc;
  z-index: 1;
  cursor: auto;
  caret-color: black;

  &:not(.no-side-panel) {
    width: calc(100% - 20%);
  }

  &:not(.no-border) {
    border-bottom: $borderValue;
  }

  .action-list {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.text-generator-container {
  display: flex;
  margin-top: 51px;
  overflow: hidden;
}

.text-preset-container {
  display: flex;
  height: calc(100% - 102px);
}

.gpt-tab {
  // overflow: hidden;
  padding: 10px !important;
  height: 46px;

  .nav-link {
    padding: 5px 15px;
    color: #a0aabe;
    font-size: 12px;

    &.active {
      border: 0;
      border-bottom: 3px solid;
      border-color: rgba(113, 99, 255, 0.4);
      color: #0d182f;
      font-weight: 600;
      border-color: #7163ff !important;
    }
    &:hover {
      border: 0;
      border-bottom: 3px solid;
      border-color: rgba(113, 99, 255, 0.4) !important;
      border-color: #7163ff;
      color: #0d182f;
      font-weight: 600;
    }
  }
  & + .tab-content {
    overflow-y: auto;
    background: #f6f6f8;
    padding: 30px;

    .tool-card-wrapper {
      flex-direction: row;
      gap: 16px;
      cursor: pointer;
      caret-color: black;
      margin: 10px 10px 10px 10px;

      .tool-card {
        align-items: center;
        display: inline-flex;
        flex-direction: column;

        height: 130px;
        width: 130px;
        background: #ffffff;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;

        align-content: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        gap: 10px;
        padding: 20px;
        margin: 6px;
        cursor: pointer;
      }
    }
  }
}

.font-faded {
  color: #a0aabe;
}

.font-medium {
  font-size: 10px;
  line-height: 12px;
}

.side-cont-wrapper {
  width: 100%;
  padding: 10px;
}

.supplementary {
  color: #a0aabe;
}

.button-icon {
  border: none;
  background-color: transparent;
  margin: 0;
  width: 24px;
  height: 24px;
  padding: 0;
  line-height: 24px;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;

  &.star {
    background-image: url("./icons/star-default.svg");
    &.selected {
      background-image: url("./icons/favourites.svg");
    }
    &:not(.selected) {
      &:hover {
        background-image: url("./icons/star-outline.svg");
      }
    }
  }

  &.trash {
    background-image: url("./icons/trash.svg");
    &.selected {
      background-image: url("./icons/trash.svg");
    }
    &:not(.selected) {
      &:hover {
        background-image: url("./icons/trash.svg");
      }
    }
  }

  &.gear {
    background-image: url("./icons/gear.svg");
    &.selected {
      background-image: url("./icons/gear.svg");
    }
    &:not(.selected) {
      &:hover {
        background-image: url("./icons/gear.svg");
      }
    }
  }

  &.warning {
    background-image: url("./icons/warning.svg");
    &.selected {
      background-image: url("./icons/warning-disabled.svg");
    }
    &:not(.selected) {
      &:hover {
        background-image: url("./icons/warning.svg");
      }
    }
  }

  &.download {
    background-image: url("./icons/download.svg");
    &.disabled {
      background-image: url("./icons/download-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.link {
    background-image: url("./icons/link.svg");
    &.disabled {
      background-image: url("./icons/link-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.move {
    background-image: url("./icons/move.svg");
    &.disabled {
      background-image: url("./icons/move-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.delete {
    background-image: url("./icons/delete-large.svg");
    &.disabled {
      background-image: url("./icons/delete-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.edit-icon {
    background-image: url("./icons/edit.svg");
    &.disabled {
      background-image: url("./icons/edit-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.save {
    background-image: url("./icons/save.svg");
    &.disabled {
      background-image: url("./icons/save-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.duplicate {
    background-image: url("./icons/duplicate.svg");
    &.disabled {
      background-image: url("./icons/duplicate-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.add {
    background-image: url("./icons/plus.svg");
    &.disabled {
      background-image: url("./icons/plus-disabled.svg");
      cursor: not-allowed;
    }
  }

  &.light-bulb {
    background-image: url("./icons/light-bulb.svg");
    &.disabled {
      background-image: url("./icons/light-bulb.svg");
      cursor: not-allowed;
    }
  }

  &.arrow-up {
    background-image: url("./icons/arrow-up.svg");
  }

  &.arrow-up-slim {
    background-image: url("./icons/arrow-up-slim.svg");

    &:hover {
      background-image: url("./icons/arrow-down-slim.svg");
    }
  }

  &.arrow-down-slim {
    background-image: url("./icons/arrow-down-slim.svg");

    &:hover {
      background-image: url("./icons/arrow-up-slim.svg");
    }
  }

  &.arrow-down {
    background-image: url("./icons/arrow-down-black.svg");
  }

  &.close-small-black {
    background-image: url("./icons/close-small-black.svg");
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }

  &.disabled {
    cursor: not-allowed;
    box-shadow: unset;
    border-radius: unset;
  }

  &.no-hover {
    box-shadow: unset;
    border-radius: unset;
  }
}

.card {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

  &-title {
    &.h5 {
      font-size: 14px;
    }
  }

  &.selected {
    border: 2px solid #3f65b7;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
  }
}

.custom-text-area-wrapper {
  caret-color: transparent;

  .button-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    .button-icon {
      display: initial;
    }
  }

  .text-area {
    pointer-events: none;
  }
}

.pagination-container {
  display: flex;
  flex-direction: row-reverse;
  font-size: 12px;
  line-height: 15px;
  margin-top: 71px;
  ul {
    margin: 0 0 20px;
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    li {
      list-style-type: none;
      margin: 0 5px;
      padding: 0 5px 5px;
      display: inline-flex;
      cursor: pointer;
      caret-color: transparent;

      // &.selected,
      &:hover {
        font-weight: 700;
        border-bottom: 1.5px solid #7163ff66;
        color: #0d182f;
        cursor: pointer;
      }

      &.selected {
        font-weight: 700;
        border-bottom: 2px solid #5586ef;
        color: #5586ef;
        cursor: pointer;
      }
    }
  }

  .pagination-item {
    &.dots:hover {
      background-color: transparent;
      cursor: pointer;
    }

    .arrow {
      &::before {
        position: relative;
        content: "";
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        border-right: 0.12em solid #5586ef;
        border-top: 0.12em solid #5586ef;
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        top: 3pt;
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.text-card {
  padding: 20px 20px 20px 40px;
  // margin-top: 51px;
  background: #f6f6f8;
}

.text-card-container {
  // column-count: 3;
  // display: inline-block;
  // display: inline-flex;
  margin: 0px 60px 60px 0px;

  column-count: 4;

  & > div {
    padding: 0 !important;
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    height: 100%;
  }
}

.text-library-card {
  position: relative;
  margin: 1px;
  cursor: pointer;
  caret-color: transparent;

  .invisible-button {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-body {
    padding: 10px 20px;
  }

  &:not(.selected) {
    &:hover {
      margin: 0;
      border: 2px solid #c8d2e6;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    }
  }
}

.pill {
  background-color: #a0aabe;
  border-radius: 16px;
  align-items: center;
  color: #ffffff;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  min-width: 80px;
  display: flex;
  justify-content: center;
  padding: 5px 25px;
  position: relative;
  height: 25px;

  .close {
    position: absolute;
    right: 12px;
    display: none;
    top: 9px;
  }

  &:hover {
    background-color: #50586a;

    .close {
      display: inline-flex;
    }
  }

  &.clickable {
    &:hover {
      cursor: pointer;
      caret-color: transparent;
    }
  }
}

.dialog {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;

  &.generic {
    .modal-header {
      justify-content: center;
      background: #f6f6f8;
      border: none;

      .modal-title {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .modal-body {
      padding: 10px 20px;
      .form-group {
        margin-bottom: 0;
      }
      &:not(.left-align) {
        text-align: center;
      }
    }
    .modal-footer {
      border: none;
      padding: 0 20px 20px;

      button {
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }

  &.filter-option {
    @include search-input;

    .row {
      margin-top: 20px;
    }

    .modal-footer {
      background: #f6f6f8;
      border: none;
    }
  }
}

.filter-options {
  display: flex;
  align-items: center;
  gap: 8px;
  .trigger-container {
    position: relative;
  }
  .trigger {
    &:hover {
      text-decoration: none;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
    }
  }
}

.filter-option {
  position: absolute !important;
  z-index: 1051;
  left: 15px;
  top: -22px;
  width: 700px !important;
}

.overlay {
  .modal-dialog {
    display: none !important;
    overflow-y: hid;
  }
}

@mixin filter-category-icon($url) {
  .form-check-label {
    &:before {
      content: url($url);
      margin-right: 5px;
    }
  }
}

.filter-checkbox {
  .form-check-input {
    margin-top: 0;
  }
  &.Ads {
    @include filter-category-icon("./icons/facebook-small.svg");
  }
  &.Ecommerce {
    @include filter-category-icon("./icons/cart-small.svg");
  }
  &.Email-marketing {
    @include filter-category-icon("./icons/email-small.svg");
  }
  &.Social-media {
    @include filter-category-icon("./icons/social-media-small.svg");
  }
  &.Video {
    @include filter-category-icon("./icons/video-cam-small.svg");
  }
  &.SEO {
    @include filter-category-icon("./icons/seo-small.svg");
  }
  &.Brainstorming {
    @include filter-category-icon("./icons/brainstorming-small.svg");
  }
}

.upload-trigger {
  margin-left: 10px;
  color: #5586ef !important;
}

#file-uploader {
  display: none;
}

.menu-button {
  &-wrapper {
    position: relative;
  }
  &-list {
    background: #fff;
    border: 1px solid #e3e5e9;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    position: absolute;
    z-index: 1060;
    top: 0;
    left: 0;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style-type: none;
        padding: 8px 12px;
        min-width: 200px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #0d182f;
        display: flex;
        gap: 10px;
        justify-content: flex-start;
        &:hover {
          background: #f1f5fb;
          cursor: pointer;
          caret-color: transparent;
        }
      }
    }
  }
}

.img-small {
  height: 14px;
  width: 14px;
}

.tab-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 48px;
}

.request-purpose {
  position: absolute;
  right: 20px;
  top: 12px;
  color: #006df0;
  padding-right: 5px;
  padding-left: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  caret-color: transparent;
  width: 160px;

  &:hover {
    box-sizing: border-box;
    border: 1px thin;
    border-color: #e5e5e5;
    border-radius: 5px;
    box-shadow: -6px 8px 6px -6px #e5e5e5;
  }
}

.preset-content-container {
  border-right: 1px solid #e9e9e9;
  // height: 100%;
  z-index: 1;
  background: #fff;
  position: relative;
  float: left;
  flex-direction: column;

  .headline {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 51px;
    padding: 20px;
    font-weight: 600;
    align-items: center;
    gap: 5px;
    border-bottom: 1px solid #e9e9e9;
    position: sticky;
    background: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }

  .preset-title {
    width: 100%;
    position: sticky;
    top: 52px;

    &:not(.no-border) {
      border-bottom: 1px solid #e9e9e9;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      line-height: 16px;
      font-style: normal;
      font-weight: normal;
      padding-left: 5px;

      &:hover {
        .button-icon {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
          border-radius: 20px;
        }
      }
    }
    &-form {
      padding: 0 16px;
      display: flex;
      gap: 5px;

      .form-group {
        flex-grow: 1;
      }

      .form-control {
        font-size: 14px;
      }

      .button-icon {
        margin-left: 5px;
      }
    }
  }

  .preset-details-form {
    padding: 12px 16px;

    .form-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
    }
    .form-control {
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 27px;
    }

    textarea {
      resize: none;
      font-size: 14px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 3px;
      border: 1px solid #dfdfdf;
      border-radius: 2px;
      margin-right: 2rem;
      // padding: 1rem;
    }

    .text-warning-message {
      border: 1px solid #d74100;

      & .form-control:focus {
        border: 1px solid #5586ef !important;
        border: 1px solid #d74100;
      }

      &:focus {
        border: 1px solid #5586ef !important;
        border: 1px solid #d74100;
      }
      &:active {
        border: 1px solid #5586ef !important;
        border: 1px solid #d74100;
      }
    }
  }

  .preset-body {
    display: flex;
    flex-direction: column;

    .form-control {
      font-size: 18px;
      font-style: normal;
      font-weight: normal;
      line-height: 27px;
    }

    .form-control:focus {
      border: 1px solid #5586ef;
      box-sizing: border-box;
      border-radius: 4px;
      outline: 0;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
      color: #0d182f;
    }
  }
}

.preset-data-container {
  margin-left: 5px;
  .label {
    margin-bottom: 0.5rem !important;
  }
}

.card-wrapper {
  padding: 0 20px 20px 50px;
}

.preset-card-wrapper {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;

  .card {
    width: 800px;

    .card-title {
      justify-content: flex-end;
    }
  }
}

.keyword-container {
  padding: 12px 12px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 3px;
  flex-wrap: wrap;

  .keyword {
    font-size: 12px;
    padding: 5px 5px 5px 11px;
    background-color: #f6f6f8;
    border-radius: 30px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e3e5e9;

    &:hover {
      background-color: #eceef2;
    }
  }
}

.empty-text-gnerator-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: static;
  width: 1049px;
  height: 794px;
  left: 0px;
  top: 41px;

  background: #f6f6f8;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin: 1px 0px;
}

.empty-text-generator-grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 32px 32px;
  justify-content: center;

  position: static;
  height: 850px;
  left: 0px;
  top: 0px;
  background: #f6f6f8;
}

.empty-text-generator {
  /* nothing_here */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: static;
  width: 420px;
  height: 249px;
  left: 314.5px;
  top: 272.5px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;
}

.btn-empty-text-generator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 17px;

  position: static;
  width: 123px;
  height: 24px;
  left: 148.5px;
  top: 225px;

  background: #006df0;
  border-radius: 4px;

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 8px 0px;
}

.illustration {
  position: relative;
  width: 381px;
  height: 193px;
  // top: 0px;

  background: #f1f1f1;
  border-radius: 16px;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 8px 0px;

  .text-illustration {
    position: relative;
    width: 63px;
    height: 16px;
    left: calc(50% - 63px / 2 + 0.5px);
    top: calc(50% - 16px / 2 + 1px);

    /* Button */

    // font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;

    color: #9f9f9f;
    cursor: none;
    pointer-events: none;
    caret-color: transparent;
  }
}

.empty-text-message {
  position: static;
  width: 420px;
  height: 16px;
  left: 0px;
  top: 201px;

  /* Regular */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  // display: flex;
  align-items: center;
  text-align: center;

  /* Main colors/Body text */

  color: #0d182f;

  /* Inside Auto Layout */

  flex: none;
  // order: 1;
  flex-grow: 0;
  margin: 8px 0px;
}

.shared-text-block {
  /* block */
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: static;
  width: 100%;
  // height: 100%;
  left: calc(50% - 1360px / 2);
  top: 24px;
  height: 890px;
  min-height: 925px;
  max-height: 890px;
  overflow: hidden;

  background: #e9e9e9;
  /* shadow style */

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px 4px 0px 0px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;
}

.shared-text-block-content {
  /* content */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 41px;

  background: #e9e9e9;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin: 1px 0px;
}

.shared-text-block-content-2 {
  /* content */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 100%;
  height: 100%;
  left: calc(50% - 1360px / 2);
  top: 0px;
  overflow-y: auto;

  background: #f6f6f8;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 1px;
}

.shared-text-block-content-3 {
  /* content */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 32px 0px;

  position: static;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  background: #f6f6f8;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;
}

.shared-text-right-panel {
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 964px;
  height: 125px;
  left: 364px;
  top: 24px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 0px;
}

.shared-text-left-panel {
  /* left */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 332px;
  height: 100%;
  left: 32px;
  top: 24px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.shared-text-header {
  /* header */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: static;
  width: 100%;
  height: 40px;
  left: calc(50% - 1360px / 2);
  top: 0px;

  background: #ffffff;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 1px 0px;
}

.shared-text-header-content {
  /* content */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px 0px 16px;

  position: static;
  width: 100%;
  height: 20px;
  left: calc(50% - 1360px / 2);
  top: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 10px 0px;
}

.headline-shared-text {
  /* Headline input */

  position: static;
  width: 332px;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Headline 2 */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  /* Button colors/Main action button/Current button blue - default */

  color: #5586ef;
}

.product-title {
  /* Product name */

  position: static;
  width: 300px;
  height: 16px;
  left: 0px;
  top: 0px;

  /* Headline 3 */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #000000;
}

.product-name-input {
  /* MyTipsTest.com */

  position: static;
  width: 300px;
  height: 16px;
  left: 0px;
  top: 24px;

  /* Regular */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Main colors/Body text */

  color: #0d182f;

  /* Inside Auto Layout */

  flex: none;
  // order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.product-desc-title {
  position: static;
  width: 300px;
  height: 16px;
  left: 0px;
  top: 0px;

  /* Headline 3 */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  // order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.product-desc-input {
  position: static;
  width: 300px;
  height: 48px;
  left: 0px;
  top: 24px;

  /* Regular */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  /* Main colors/Body text */

  color: #0d182f;

  /* Inside Auto Layout */

  flex: none;
  // order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.shared-keywords {
  position: static;
  width: 300px;
  height: 16px;
  left: 0px;
  top: 0px;

  /* Headline 3 */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  // order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.shared-keywords-input {
  position: static;
  width: 300px;
  height: 16px;
  left: 0px;
  top: 24px;

  /* Regular */

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  /* Main colors/Body text */

  color: #0d182f;

  /* Inside Auto Layout */

  flex: none;
  // order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

.headline-output {
  position: static;
  width: 964px;
  height: 20px;
  left: 0px;
  top: 0px;

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;

  color: #5586ef;

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 10px 10px;
}

.shared-card-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;

  position: static;
  width: 964px;
  // height: 84px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 16px;
}

.shared-card-text-hover {
  /* Inputs */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;

  position: static;
  width: 940px;
  height: 68px;
  left: 12px;
  top: 8px;

  background: #ffffff;
  /* Input / border color style */

  border: 1px solid #e3e5e9;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.shared-card-text-input {
  position: static;
  width: 891px;
  left: calc(50% - 891px / 2 - 14.5px);
  top: 8.82%;
  bottom: 8.82%;

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;

  /* Main colors/Body text */

  color: #0d182f;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 4px;
}

.shared-right-panel-item {
  /* item */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  width: 964px;
  height: 84px;
  left: 0px;
  top: 210px;

  /* Inside Auto Layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}

.login {
  border: 1px solid #d3d3d3;
  border-radius: 0.5em;
  margin-bottom: 1em;
  margin: auto;
  text-align: left;
  width: 400px;
  height: 400px;
  padding: 50px;
  background: #f6f6f8;
  display: grid;
  align-content: center;
  &.form {
    text-align: left;
  }

  &.form-control {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: left;

    color: #b5b5b5;
    width: 300px;
  }
  &.form-label {
    width: 300px;
    height: 18px;

    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    color: #ffffff;
    padding-right: 10px;
    margin-right: 10px;
  }
  &.button {
    width: 258px;
    height: 18px;

    // font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    // line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #ffffff;
  }
  .form-control:not(textarea) {
    height: 45px !important;
  }
}

.login-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  color: #0d182f;
}

.login-textarea {
  width: 300px;
  height: 34px;

  background: #ffffff;
  border: 1px solid #e7e8ec;
  box-sizing: border-box;
}

.login-btn-text {
  width: 300px;
  height: 18px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
}

.login-btn {
  width: 100%;

  background: #7163ff;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  /* margin: 10px; */
  border: 1px solid #e7e8ec;
}

.template-text {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 100px;
  word-wrap: break-word;
  color: #000000;
  cursor: pointer;
  caret-color: transparent;
}

.template-most-popular {
  /* Most popular */

  position: static;
  width: 83px;
  height: 16px;
  left: 0px;
  top: 4px;

  &-h3 {
    // font-family: Proxima Nova;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  /* identical to box height, or 114% */

  &-alignment {
    display: flex;
    align-items: center;

    color: #000000;
  }

  /* Inside Auto Layout */
  &-layout {
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
  }
}

.title-most-popular {
  /* title */
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;

  position: static;
  width: 1296px;
  height: 24px;
  left: 32px;
  top: 32px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}

.all-content {
  /* content */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: static;
  width: 1360px;
  height: 754px;
  left: 0px;
  top: 81px;

  /* Main colors/Widget */

  background: #f6f6f8;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  margin: 1px 0px;
}

.text-cards {
  /* row */

  /* Auto Layout */

  // display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  // width: 1296px;
  // height: 130px;
  left: 32px;
  top: 72px;

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
  grid-gap: 16px;
  // margin-bottom: 50px;
}

.all-other-templates {
  /* title */
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0px;

  position: static;
  width: 1296px;
  height: 24px;
  left: 32px;
  top: 218px;

  /* Inside Auto Layout */

  flex: none;
  order: 4;
  align-self: stretch;
  flex-grow: 0;
  margin: 16px 0px;
}

.search-bar {
  /* dropdown_search_bar */

  /* Auto Layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px 4px 5px;

  position: static;
  width: 294px;
  height: 26px;
  left: 8px;
  top: 6px;

  background: #ffffff;
  border: 1px solid #e3e5e9;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 8px 0px;
}

#toolbar-category {
  padding: 10px 20px;
  height: 51px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  margin-left: 5px;
  z-index: 100;
  background: #fcfcfc;
  cursor: auto;
  caret-color: black;

  // &:not(.no-side-panel) {
  //   width: calc(100% - 264px);
  // }

  &:not(.no-border) {
    border-bottom: $borderValue;
  }

  .action-list {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.search {
  padding: 0px 0px 0px 8px;
  width: 345px;
}

.search-default {
  position: static;
  width: 347px;
  height: 28px;
  left: 8px;
  top: 0px;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.search-width {
  position: absolute;
  left: 0.29%;
  right: 0.29%;
  top: 3.33%;
  bottom: 3.33%;
}

.search-icon-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: absolute;
  width: 61px;
  height: 19.9px;
  left: 6.22px;
  top: 4px;

  border-radius: 4px;
}

.search-text-area {
  // position: absolute;
  left: 0.29%;
  right: 0.29%;
  top: 3.33%;
  bottom: 3.33%;
  display: flex;

  background: #ffffff;
  /* Input / border color style */

  border: 1px solid #e3e5e9;
  border-radius: 4px;
}

.form-search-text {
  position: static;
  left: 39.34%;
  right: 0%;
  top: 14.82%;
  bottom: 14.82%;

  // font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  color: #6b758c;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.search-img-class {
  padding: 5px;
}

// .form-control:input{
//   border:none;
// }

#search-text-input {
  border-top: thin solid #e5e5e5;
  border-right: thin solid #e5e5e5;
  border-bottom: 0;
  border-left: thin solid #e5e5e5;
  box-shadow: 0px 1px 1px 1px #e5e5e5;
  float: left;
  height: 17px;
  margin: 0.8em 0 0 0.5em;
  outline: 0;
  padding: 0.4em 0 0.4em 0.6em;
  width: 183px;
  font-size: 14px;
}

#button-holder {
  background-color: #f1f1f1;
  border-top: thin solid #e5e5e5;
  box-shadow: 1px 1px 1px 1px #e5e5e5;
  cursor: pointer;
  caret-color: transparent;
  float: left;
  height: 27px;
  margin: 11px 0 0 0;
  text-align: center;
  width: 50px;
}

#button-holder img {
  margin: 4px;
  width: 20px;
}

.modal-request-form {
  // position: relative;
  // width: 525px;
  // height: 294px;

  &.generic {
    .modal-header {
      justify-content: center;
      background: #f6f6f8;
      border: none;
      position: static;
      width: 100%;
      height: 40px;
      left: 0px;
      top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .modal-title {
      font-size: 14px;
      font-weight: 600;
    }

    .modal-body {
      margin-top: 20px;
      padding: 10px 20px;
      height: 100px;
      text-align: left;

      .form-group {
        margin-bottom: 0;
        padding-bottom: 20px;
      }

      .form-label {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        // font-size: 12px;
        line-height: 16px;
      }

      .form-control:not(textarea) {
        resize: none;
        overflow: hidden;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
      }
      &:not(.left-align) {
        text-align: left;
        font-size: 14px;
      }
    }

    .modal-footer {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      padding: 50px 20px 20px 24px;

      position: static;
      width: 100%;
      height: 44px;
      left: 0px;
      top: 250px;

      /* Inside Auto Layout */

      flex: none;
      order: 2;
      align-self: stretch;
      flex-grow: 0;
      margin: 32px 0px;

      button {
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}

.form-input-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 10px;

  position: static;
  width: 390px;
  height: 80px;
  left: 87px;
  top: 0px;

  background: #ffffff;
  /* Input / border color style */

  border: 1px solid #e3e5e9;
  box-sizing: border-box;
  border-radius: 4px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 1;
  margin: 0px 8px;
}

#toolbar-preset {
  padding: 10px 20px;
  height: 51px;
  display: inline-flex;
  justify-content: space-between;
  width: calc(100% - 20%);
  position: fixed;
  margin-left: 0px;
  z-index: 100;
  background: #fcfcfc;
  cursor: auto;

  &:not(.no-side-panel) {
    width: calc(100% - 20%);
  }

  &:not(.no-border) {
    border-bottom: $borderValue;
  }

  .action-list {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.has-search .form-control {
  padding-left: 2.2rem;
  width: 350px;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.4;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e5e9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
}

.has-search .form-control-feedback {
  z-index: 2;
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  margin: 6px;
  line-height: 1.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
  border-style: none;
  position: absolute;
  left: 0.29%;
  right: 0.29%;
  top: 3.33%;
  bottom: 3.33%;
}

.share-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;

  position: static;
  width: 420px;
  height: 28px;
  left: 0px;
  top: 0px;
  font-size: 9px;

  background: #ffffff;

  border: 1px solid #e3e5e9;
  box-sizing: border-box;
  border-radius: 4px;
  color: #a0aabe;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  // flex-grow: 1;
  margin: 0px 4px;
  resize: none;
  overflow: hidden;
}

.hide {
  display: none;
}

.modal-inline-flex {
  display: inline-flex;

  .button-icon {
    // display: none;
    position: absolute;
    // top: 0;
    right: 20px;
  }

  &:hover {
    .button-icon {
      display: initial;
    }
  }
}

.request-purpose-text-area {
  border: none;
  width: 100%;
  height: 100%;
  resize: none;
  padding: 10px;
  z-index: 2;
  background-color: #fff;
  background-clip: padding-box;
  color: #495057;
  outline: none;
}

.request-purpose-border-style {
  height: 80px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  // border: 1px solid #ced4da;
  border: 1px solid #e3e5e9;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 4px;
}

.textLength {
  text-align: right;
  margin-right: 10px;
  // margin-top: -20px;
  color: black;
  font-size: 8px;
  pointer-events: none;
  margin-bottom: 0;
}

.text-title {
  border: none;
  background: none;
  font-size: 24px;
  line-height: 28px;
  cursor: auto;
  caret-color: black;
  outline: none;
}

.edit-title {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  // border: 1px solid #e3e5e9;
  // border-radius: 0.25rem;
  display: flex;
  padding: 4px;
  outline: none;
  border: none;

  .form-control {
    width: 400px;
    border: none;
    outline: none;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
  }

  .form-group {
    margin-bottom: 1rem !important;
  }

  .img {
    pointer-events: none;
    z-index: 2;
    display: block;
    width: 1.1rem;
    height: 1.1rem;
    margin: 6px;
    line-height: 1.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
    border-style: none;
    position: absolute;
    left: 0.29%;
    right: 0.29%;
    top: 3.33%;
    bottom: 3.33%;
    padding: 3px;
    color: #aaa;
    outline: none;
    border: none;
  }

  &.focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
}

.edit-title:focus {
  border: none;
  outline: none;
}

.disabledCursor {
  cursor: default;
  pointer-events: none;
}

.preset-sidepanel-input {
  display: flex;
  flex-direction: column;
  position: sticky;
  // height: 900px;
  // min-height: 785px;

  left: 0px;
  top: 41px;
  background: #ffffff;
  // flex: none;
  align-self: stretch;
  margin: 1px 0px;
  // width: 20%;
}

.select-folder {
  position: static;
  width: 67px;
  height: 16px;
  left: 24px;
  top: 0px;

  /* Regular */

  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: right;

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.save-folder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: center;

  .form-control {
    // width: 241px;
    margin: 10px;
    position: static;

    width: 320px;
    height: 28px;
    left: 107px;
    top: 0px;
  }
  .form-check {
    margin: 15px;
    width: 100px;

    /* Selection/Hover */

    .form-check-input {
      margin-top: 1px;
      vertical-align: middle;
      border: 2.5px solid #c8d2e6;
    }
  }

  .modal-footer-save {
    padding: 0 20px 20px;
  }
}

.modal-select-template {
  &.generic {
    // width: 1200px;
    align-items: center;
    justify-content: center;
    display: flex;

    .modal-header {
      justify-content: center;
      background: #f6f6f8;
      border: none;
      position: static;
      width: 100%;
      height: 40px;
      left: 0px;
      top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .modal-title {
      font-size: 14px;
      font-weight: 600;
    }

    .modal-content {
      width: 1200px;
    }
    .modal-body {
      width: 100%;
      padding: 0 !important;

      .form-group {
        margin-bottom: 0;
        padding-bottom: 20px;
      }

      .form-label {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }

      .form-control:not(textarea) {
        resize: none;
        overflow: hidden;
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
      }
      &:not(.left-align) {
        text-align: left;
        font-size: 14px;
      }
    }
    .modal-dialog {
      max-width: 1200px;
    }

    .modal-footer {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      // padding: 50px 20px 20px 24px;

      position: static;
      left: 0px;
      top: 250px;
      width: 100%;

      /* Inside Auto Layout */

      flex: none;
      // order: 2;
      // align-self: stretch;
      // flex-grow: 0;
      // margin: 32px 0px;

      button {
        margin: 0;
        &:first-child {
          margin-right: 16px;
        }
      }
    }
  }
}

#toolbar-preset-template {
  padding: 10px 20px;
  height: 51px;
  display: flex;
  justify-content: space-between;
  margin-left: 5px;
  // z-index: 100;
  background: #fcfcfc;
  width: 1190px;
  position: fixed;

  &:not(.no-border) {
    border-bottom: $borderValue;
  }

  .action-list {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.tab-container-template-modal {
  position: relative;
  /* display: flex; */
  flex-direction: column;
  flex-grow: 1;
  margin-top: 48px;
  // height: 650px;
  overflow: hidden;
}

.edit-project-name {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e3e5e9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
  padding: 4px;
  width: 230px;
  height: 28px;
  left: 0px;
  top: 20px;
  font-size: 12px;
  line-height: 14px;
}

.edit-project-name:focus {
  border: 1px solid #5586ef;
  box-sizing: border-box;
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
}

.textAreaStyle:focus {
  border: 1px solid #5586ef;
  box-sizing: border-box;
  border-radius: 4px;
  outline: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
}

.time {
  display: contents;
}

.text-card-library {
  border-bottom: none !important;

  .title {
    @include title-mixin;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px 20px;
      align-items: center;
      font-size: 12px;

      &:hover {
        background-color: $side-panel-list-hover-bg;

        .action-button {
          display: initial;
        }
      }

      .head {
        & > span {
          font-weight: 600;
        }
      }

      .middle,
      .footer {
        font-size: 12px;
      }
    }
  }
}

.form-settings-content {
  border: 1px solid #d3d3d3;
  margin-bottom: 1em;
  margin-left: 20%;
  margin-right: auto;
  margin-top: 40px;
  text-align: left;
  width: 1000px;
  border-right: none;

  .form-control {
    padding-left: 1em;
    font-size: 14px;
  }

  .form-group {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 14px;

    .button {
      font-size: 14px;
      width: 150px;
    }
  }
  .form-label {
    font-size: 14px;
    // color: cornflowerblue;
  }
}

.form-settings {
  display: inline-flex;

  .span {
    text-align: center;
  }
}

.form-settings-right-panel {
  width: 500px;
  border: 1px solid #d3d3d3;
  // border-radius: .5em;
  margin-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  text-align: right;
  text-align: left;

  .form-group {
    padding-left: 1em;
    padding-right: 1em;
    font-size: 14px;
    display: grid;
  }
}

.label-settings-header {
  font-size: 14px;
  border: 1px thin #d3d3d3;
  border-radius: 0.5em;
  background: #f6f6f8;
  width: -webkit-fill-available;
  text-align: left;
  padding-left: 15px;
  /* padding-left: 15px; */
  border-radius: 0.25rem;
  margin-bottom: 15px;
  line-height: 40px;
  // font-weight: 600;
  color: #007bff;
}

.signup {
  border: 1px solid #d3d3d3;
  border-radius: 0.5em;
  margin-bottom: 1em;
  margin: auto;
  text-align: left;
  width: 500px;
  height: 500px;
  padding: 50px;
  background: #f6f6f8;
  display: grid;

  .form {
    width: 200px;
  }

  .form-control:not(textarea) {
    height: 50px !important;
  }
  .btn {
    width: 100%;
    margin-bottom: 10px;
  }

  .row {
    margin-bottom: 0rem !important;
  }
}

.separator {
  color: #000000;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  position: relative;
  user-select: none;
  text-align: center;
  padding: 16px 0;

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: calc(50% - 24px);
    height: 1px;
    background-color: #d1d1d1;
  }
}

.auth-options {
  text-align: left;
}

.preset-form-check {
  display: inline-flex;
  align-items: center;

  .form-check {
    .input[type="checkbox"]:checked {
      background: #3f65b7;
    }
  }

  .form-check-input {
    margin-top: 0.1rem;
  }
}

.form-check-all {
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
}

.edit {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  width: 843px;
  background: #ffffff;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 4px;

  .edit-header {
    /* Auto Layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 0px;

    position: static;
    width: 843px;
    height: 40px;
    left: 0px;
    top: 0px;

    /* Main colors/Widget */

    background: #f6f6f8;

    /* Inside Auto Layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
  }

  .edit-inputs {
    /* Inputs */

    /* Auto Layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 10px;

    position: static;
    width: 811px;
    height: 278px;
    left: 16px;
    top: 16px;
    outline: none;

    /* Main colors/White */

    background: #ffffff;
    /* Input colors/Border */

    border: 1px solid #e3e5e9;
    box-sizing: border-box;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    /* Inside Auto Layout */

    flex: none;
    // order: 0;
    // align-self: stretch;
    flex-grow: 0;
    margin: 8px 0px;
  }
}

.preset-right-content-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-left: 20%;
  position: absolute;
  width: calc(100% - 20%);
  height: calc(100% - 51px);
  background: #f6f6f8;
  min-width: calc(100% - 20%);
  max-width: calc(100% - 25%);
  overflow-y: auto;
}

.edit-modal-body {
  position: static;
  width: 811px;

  left: 16px;
  top: 16px;

  .form-control {
    height: 278px;
  }
}

.edit-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  border: none;

  .button {
    margin: 0px 10px;
  }
}

.edit-content-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
  position: relative;
  border: 1px solid #ced4da;
}

.settings {
  display: inline-flex;
  justify-content: space-between;
  width: 320px;
  border: 1px solid #ced4da;
  background: #fff;
  padding: 0.25rem 0.5rem;
  line-height: 15px;
  border-radius: 0.2rem;
  margin-bottom: 5px;
  font-size: 14px;
  align-items: center;
  color: #4d5763;

  .span {
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    padding: 5px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin: 5px 0;
    align-items: center;
    cursor: pointer;
    caret-color: transparent;

    &:hover {
      background-color: $side-panel-list-hover-bg;
      border-radius: 2px;

      .button-icon {
        display: initial;
      }
    }
  }
  .folder-action {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: auto;

    .button-icon {
      display: none;
    }
  }
}

.link-style {
  margin-top: 5px;
  padding: 0.5rem 1rem;
  color: #4d5763;
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;

  &:hover:before {
    content: "";
    width: 45%;
    opacity: 0.8;
    text-decoration: none;
  }

  &:hover {
    border: 0;
    border-bottom: 3px solid;
    border-color: rgba(113, 99, 255, 0.4) !important;
    border-color: #7163ff;
    color: #0d182f;
    font-weight: 600;
    text-decoration: none;
  }

  &.active {
    border: 0;
    border-bottom: 3px solid;
    border-color: rgba(113, 99, 255, 0.4);
    color: #0d182f;
    font-weight: 600;
    border-color: #7163ff !important;
  }
}

.header-style {
  color: #4d5763;
  text-align: center;
  padding: 10px;
  padding-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
}

.preset-text-area {
  border-style: none;
  border-color: Transparent;
  overflow: auto;
  outline: none;
  border: none;
  cursor: none;

  *:focus {
    outline: none;
  }
}

// .Toastify__toast-container--bottom-center {
//   left: 1em !important;
//   left: 22%;
//   width: 800px;
// }
